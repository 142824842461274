import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter, Route} from "react-router-dom";
import './main.css';
import './react-contextify-styling.css';


ReactDOM.render(
    <BrowserRouter><Route path={"/"} component={App}/></BrowserRouter>, document.getElementById('root'));
