import React from 'react';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";

import Context from "./context/context";
import cx from "classnames";
import StudentTiles from "./studentTitles/studentTiles";
import styles from './appCss.module.css';
import toolStyles from './tools.module.css';
import StudentSubmissionInspector from "./submissionInspector/submissionInspector";
import Login from "./login/Login";
import StudentToolBox from "./toolboxes/student/studentToolBox";
import AdminToolBox from "./toolboxes/admin/adminToolBox";
import ArchiveTiles from "./studentTitles/archiveTiles";
import CatchAllException from "./CustomError/CatchAllException";
import FacilitatorToolBox from "./toolboxes/facilitator/facilitatorToolBox";
import {getPageTitle} from "./_helper";
import {Modal} from 'react-bootstrap';


class App extends React.Component {

    state = {
        prelimFinished: false,
        firstName: null,
        lastName: null,
        username: null,
        apiLocation: null,
        _id: null,
        role: null,
        studentTitles: [],
        modal: false
    };


    validateCookie = () => {
        this.fetch('api/auth/validate')
            .then(res => res.json())
            .then((res) => {
                if (res.redirected) {
                    if (this.props.history.location.pathname === '/tools') {
                        this.setState({redirect: this.props.history.location}, () => {
                            this.props.history.replace(res.url);
                        });
                    }
                } else {
                    if (this.state.redirect) {
                        this.props.history.push(this.state.redirect);
                    }
                    this.setState({
                        firstName: res.firstName,
                        lastName: res.lastName,
                        username: res.username,
                        apiLocation: res.apiLocation,
                        _id: res._id,
                        role: res.role,
                    });
                }
            })
            .finally(() => {
                this.setState({prelimFinished: true});
            });
    };

    login = (_id, firstName, lastName, username, apiLocation, role) => {
        this.setState({
            firstName: firstName,
            lastName: lastName,
            username: username,
            apiLocation: apiLocation,
            _id: _id,
            role: role,
        });
        if (!this.state.redirect) {
            this.props.history.push('/');
        } else {
            this.props.history.push(this.state.redirect);
            this.setState({redirect: null});
        }
    };

    logout = () => {
        this.fetch('api/auth/logout')
            .then(() => {
                this.setState({
                    firstName: null,
                    lastName: null,
                    username: null,
                    apiLocation: null,
                    _id: null,
                    role: null,
                    modal: false
                });
                this.props.history.push('/');
                document.title = getPageTitle();
            });
    };

    fetch = (p, m, b, excludeHeader = false, additionalHeaders = {}) => {
        const init = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        if (excludeHeader) {
            init.headers = {};
        }
        if (additionalHeaders) {
            init.headers = {...additionalHeaders, ...init.headers}
        }
        if (m) {
            init.method = m;
        }
        if (b) {
            init.body = b;
        }
        return fetch(`${window.location.origin}/${p}`, init).then(res => {
                if (res.status === 422) {
                    this.logout();
                }
                return res;
            }
        );
    };

    componentDidMount() {
        document.title = getPageTitle();
        this.validateCookie();
    }


    getUserLink = () => {
        if (this.state.role === "student") {
            if (this.state.firstName && this.state.lastName && this.state._id) {
                return <NavLink className={styles.title}
                                to={`/view/${this.state._id}`}>{`${this.state.firstName} ${this.state.lastName}`}</NavLink>
            }
        } else {
            if (this.state.firstName && this.state.lastName && this.state.role) {
                return <NavLink className={styles.title}
                                to={`/tools`}>{`${this.state.firstName} ${this.state.lastName} (${this.state.role})`}</NavLink>
            }
        }
        return null;
    };


    handleStudentTileSelect = (id, folder, displayName, src_path, live_path) => {
        if (folder) {
            this.setState({
                modal: {
                    id: id,
                    folder: folder,
                    src_path: src_path,
                    displayName: displayName,
                    live_path: live_path
                }
            });
        } else {
            this.props.history.push(src_path);
        }
    };

    handleHideModal = () => {
        this.setState({modal: false});
    };

    render() {
        if (this.state.prelimFinished) {
            return (
                <Context.Provider value={{
                    _id: this.state._id,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    username: this.state.username,
                    apiLocation: this.state.apiLocation,
                    role: this.state.role,
                    login: this.login,
                    logout: this.logout,
                    fetch: this.fetch,
                    history: this.props.history
                }}>
                    <div className="App">
                        <Modal show={this.state.modal}
                               onHide={this.handleHideModal}>
                            {this.state.modal && <React.Fragment>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.state.modal.displayName}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <iframe title={this.state.modal.id} scrolling={"yes"}
                                            src={this.state.modal.live_path}
                                            className={styles.iframe}/>
                                </Modal.Body>
                                <Modal.Footer>
                                    <NavLink
                                        className={cx(toolStyles.button, toolStyles.largeButtonText, toolStyles.centeredText)}
                                        to={this.state.modal.src_path}
                                        onClick={this.handleHideModal}>
                                        View Website Source</NavLink>
                                    <a className={cx(toolStyles.button, toolStyles.largeButtonText, toolStyles.centeredText)}
                                       href={this.state.modal.live_path}>View Full Website</a>
                                </Modal.Footer>
                            </React.Fragment>}
                        </Modal>
                        <header className={styles.headerWrapper}>
                            <NavLink className={styles.homeButton} to={'/'}>IST-226</NavLink>
                            {this.getUserLink()}
                            {this.state._id && <NavLink to={'/tools'} className={toolStyles.button}>Tools</NavLink>}
                            {!this.state._id ? <button className={toolStyles.button} onClick={() =>
                                    this.props.history.push('/login')}>Login</button> :
                                <button className={toolStyles.button} onClick={this.logout}>Logout</button>}
                        </header>
                        <div className={styles.mainWrapper}>
                            <Switch>
                                <Route exact path={'/login'} component={Login}>
                                    {this.state._id && <Redirect to={"/"}/>}
                                </Route>
                                {!this.state.role &&
                                <Route path={'/tools'} render={() => <h1>No Toolkit</h1>}>
                                    <Redirect to={'/login'}/>
                                </Route>}
                                <Route exact path={'/'} render={(props) => <StudentTiles {...props}
                                                                                         handleStudentTileSelect={this.handleStudentTileSelect}/>}/>
                                <Route exact path={'/archive'} component={ArchiveTiles}/>
                                <Route path={'/view/:studentId'} component={StudentSubmissionInspector}/>
                                {this.state.role === "student" && <Route path={'/tools'} component={StudentToolBox}/>}
                                {this.state.role === "admin" && <Route path={'/tools'} component={AdminToolBox}/>}
                                {["adjunct", "instructor"].includes(this.state.role) &&
                                <Route path={'/tools'} component={FacilitatorToolBox}/>}
                                <Route component={CatchAllException}/>
                            </Switch>
                        </div>
                        <footer className={styles.footerWrapper}>
                            {/*<span className={styles.content}>&copy; Henry Ecker {new Date().getFullYear()}</span>*/}
                        </footer>
                    </div>
                </Context.Provider>
            );
        } else {
            return null;
        }
    }
}

export default App;