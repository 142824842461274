import React, {Component} from 'react';
import toolStyles from "../../tools.module.css";
import cx from "classnames";
import Context from "../../context/context";


class SubmissionHistoryView extends Component {
    static contextType = Context;

    state = {submissions: []};

    componentDidMount() {
        this.context.fetch('api/users/submissionHistory')
            .then(res => res.json())
            .then(res => this.setState({submissions: res}));
    }


    handleClearHistory = (ev) => {
        ev.preventDefault();
        if (this.context.role === "admin") {
            if (window.confirm("Are you sure you want to to clear submission history? This cannot be undone.")) {
                this.context.fetch('api/users/submissionHistory', "DELETE").then(res => {
                    if (res.status === 200 || res.status === 204) {
                        this.setState({submissions: []})
                    }
                });
            }
        }
    };

    render() {
        return (<div className={toolStyles.tool}>
                <div className={toolStyles.toolInternal}>
                    <h2 className={toolStyles.toolSubTitle}>Submission History</h2>
                    <table className={cx(toolStyles.table, toolStyles.abridged)}>
                        <thead>
                        <tr className={toolStyles.tr}>
                            <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>First Name</th>
                            <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Last Name</th>
                            <th className={cx(toolStyles.cell, toolStyles.th)}>Submission</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.submissions.length > 0 && this.state.submissions.map(({_id, firstName, lastName, time}) => {
                            const d = new Date(time);
                            return <tr key={_id} className={toolStyles.tr}>
                                <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{firstName}</td>
                                <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{lastName}</td>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>{`${d.toLocaleString()}`}</td>
                            </tr>
                        })}
                        {(this.state.submissions.length > 0 && this.context.role === "admin") &&
                        <tr className={toolStyles.tr}>
                            <td className={cx(toolStyles.cell, toolStyles.td)} colSpan={3}>
                                <button onClick={this.handleClearHistory}
                                        className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                        type={"button"}>
                                    Clear Submission History
                                </button>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default SubmissionHistoryView;