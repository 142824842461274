import React from 'react';

import Context from "../context/context";
import FileTree from "../FileTree/FileTree";
import styles from './submissionInspector.module.css';
import SubmissionPlainTextView from "./submissionPlainTextView";
import cx from 'classnames';
import {getPageTitle} from "../_helper";

class StudentSubmissionInspector extends React.Component {
    static contextType = Context;

    state = {files: [], src: '', displayName: '', selected: '', lastHtml: ''};

    htmlRe = new RegExp(/htm$|html$/);


    componentDidMount() {
        document.title = getPageTitle("Source View");
        this.context.fetch(`api/students/submission/${this.props.match.params.studentId}`)
            .then(res => {
                if (res.status === 400) {
                    throw new Error("Invalid Student")
                }
                return res;
            })
            .then(res => res.json())
            .then(resData => {
                document.title = getPageTitle(resData.displayName, "Source View");
                this.setState({
                    files: resData.files,
                    src: resData.src,
                    displayName: resData.displayName,
                    ...this.getPathFromSearchParams(resData.src)
                });
            })
            .catch(() => {
                this.props.history.push('/');
            });
    }

    getPathFromSearchParams = (src) => {
        const u = new URLSearchParams(this.props.history.location.search);
        if (src && u.has('path')) {
            const update = {};
            update.selected = u.get('path');
            if (u.has('html')) {
                update.lastHtml = u.get('html');
            } else {
                update.lastHtml = `${src}/${update.selected}`;
            }
            return update;
        }
        return {selected: `/index.html`, lastHtml: `${src}/index.html`};
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const p = this.getPathFromSearchParams(this.state.src);
        if (p && p.selected !== this.state.selected) {
            this.setState(p);
        }
    }


    handleFileClickAction = (file) => {
        if (file.type === "file") {
            const u = new URLSearchParams();
            const selected = file.path.split(this.state.src)[1];
            u.set('path', selected);
            if (selected && this.htmlRe.test(selected)) {
                u.set('html', `${this.state.src}${selected}`);
            } else if (this.state.lastHtml) {
                u.set('html', this.state.lastHtml);
            }
            this.props.history.push({search: u.toString()});
        }
    };

    render() {
        if (this.state.files.length > 0) {
            return <div className={styles.wrapper}>
                <div
                    className={cx(styles.gridElementWrapper, styles.gridElementBottomBorder, styles.gridElementRightBorder)}>
                    <h2 className={styles.sectionTitle}>{this.state.displayName}</h2>
                </div>
                <div
                    className={cx(styles.gridElementWrapper, styles.gridElementBottomBorder, styles.gridElementRightBorder)}>
                    <h2 className={styles.sectionTitle}>
                        <a className={styles.sectionTitle} href={this.state.lastHtml}>{this.state.lastHtml}</a>
                    </h2>
                </div>

                <div className={cx(styles.gridElementWrapper, styles.gridElementBottomBorder)}>
                    <h2 className={styles.sectionTitle}>
                        <a className={styles.sectionTitle} href={`${this.state.src}${this.state.selected}`}>
                            {this.state.selected}
                        </a>
                    </h2>
                </div>
                {this.state.files &&
                <div
                    className={cx(styles.gridElementWrapper, styles.gridElementRightBorder, styles.gridElementBottomBorder)}>
                    <div className={styles.fileTreeWrapper}>
                        <FileTree data={this.state.files} openMode={'closed'} action={this.handleFileClickAction}/>
                    </div>
                </div>}
                {this.state.src &&
                <div
                    className={cx(styles.gridElementWrapper, styles.gridElementRightBorder, styles.gridElementBottomBorder)}>
                    <div className={styles.iframeWrapper}>
                        <iframe title={this.state.displayName} className={styles.iframe} src={this.state.lastHtml}/>
                    </div>
                </div>}
                {this.state.selected &&
                <div className={cx(styles.gridElementWrapper, styles.gridElementBottomBorder)}>
                    <SubmissionPlainTextView src={this.state.selected} studentId={this.props.match.params.studentId}/>
                </div>}
            </div>;
        } else if (this.state.displayName) {
            return <h1 className={styles.sectionTitle}>{this.state.displayName} has not uploaded yet.</h1>
        } else {
            return null;
        }
    }
}

export default StudentSubmissionInspector;