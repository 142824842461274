import React from 'react';
import styles from './studentTiles.module.css'
import {generateContrastingBgFgColor} from "../_helper";

class IFramePlaceHolder extends React.Component {

    state = {colorCSSProp: generateContrastingBgFgColor()};


    render() {
        return <div className={styles.iframe} style={this.state.colorCSSProp}>
            <div style={{display: "table", textAlign: "center", height: "100%", width: "100%"}}>
                <h1 style={{display: "table-cell", verticalAlign: "middle"}}>
                    {this.props.placeHolderText}
                </h1>
            </div>
        </div>
    }
}

export default IFramePlaceHolder;