import React from 'react';
import PropTypes from 'prop-types';

import styles from './filetree.module.css'
import CloseFolder from './assets/directory.png';
import OpenFolder from './assets/folder_open.png';

class Folder extends React.Component {
    triggerAction = () => {
        if (this.props.action) {
            const file = {
                id: this.props.id,
                name: this.props.name,
                path: this.props.path,
                type: 'directory'
            };

            this.props.action(file);
        }
    };

    render() {
        const icon = this.props.open ? this.props.icons.open : this.props.icons.close;

        return (
            <div className={styles.folder} onClick={() => {
                this.props.toggleFolder();
                this.triggerAction();
            }}>
                <img alt="" src={icon} className={styles.file_icon}/>
                <span className={styles.nested_label}>{this.props.name}</span>
            </div>
        );
    }
}

Folder.propTypes = {
    name: PropTypes.string.isRequired,
    open: PropTypes.bool,
    path: PropTypes.string.isRequired,
    icons: PropTypes.shape({
        open: PropTypes.string,
        close: PropTypes.string,
    }),
    toggleFolder: PropTypes.func.isRequired,
    action: PropTypes.func,
    currentSelection: PropTypes.string,
    conditionalToggle: PropTypes.func,
};

Folder.defaultProps = {
    icons: {
        open: OpenFolder,
        close: CloseFolder,
    },
    open: false,
};

export {
    Folder,
};