import React from 'react';
import PropTypes from 'prop-types';

import {TreeNode} from './TreeNode';
import styles from './filetree.module.css'
import DefaultFileIcon from "./assets/file.png";

class FileTree extends React.Component {
    constructor(props) {
        super(props);
        this.extMap = {
            images: {
                exts: ['ai', 'bmp', 'gif', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif'],
                mapToName: 'picture.png'
            },
            video: {
                exts: ['3g2', '3gp', 'avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'mpeg', 'rm', 'swf', 'vob', 'wmv'],
                mapToName: 'film.png'
            },
            code: {
                exts: ['js', 'php', 'py'],
                mapToName: 'code.png'
            },
            applications: {
                exts: ['apk', 'bat', 'bin', 'cgi', 'pl', 'com', 'exe', 'gadget', 'jar', 'wsf'],
                mapToName: 'application.png'
            },
            compressed: {
                exts: ['7z', 'arg', 'deb', 'pkg', 'rar', 'rpm', 'gz', 'z', 'zip'],
                mapToName: 'zip.png'
            }
        };
        this.icons = {};
        let r = require.context('./assets');
        r.keys().forEach((item) => {
            this.icons[item.replace('./', './assets/')] = r(item);
        });
    }

    determineIcon = (pathParam) => {
        let ext = pathParam.replace(/^.*\./g, '');
        Object.values(this.extMap).some((o) => {
            if (o.exts.some((e) => e === ext)) {
                ext = o.mapToName;
                return true;
            } else {
                return false;
            }
        });
        let exists = Object.keys(this.icons).filter((k) => k.indexOf(ext) > 0)[0];
        return (exists) ? this.icons[exists] : DefaultFileIcon;
    };

    render() {
        return (
            <div className={styles.folders_tree}>
                {this.props.data.map((data) => {
                    return (
                        <TreeNode
                            key={data.ino}
                            id={data.ino}
                            openMode={this.props.openMode}
                            name={data.name}
                            path={data.path}
                            currentSelection={this.props.currentSelection}
                            conditionalToggle={this.props.conditionalToggle}
                            determineIcon={this.determineIcon}
                            icon={this.determineIcon(data.path)}
                            children={data.children}
                            action={this.props.action}
                        />
                    );
                })}
            </div>
        );
    }
}

FileTree.propTypes = {
    data: PropTypes.array.isRequired,
    openMode: PropTypes.string.isRequired,
    currentSelection: PropTypes.string,
    conditionalToggle: PropTypes.func,
    action: PropTypes.func,
};

export default FileTree;