import React from 'react';
import Context from "../context/context";
import styles from './login.module.css';
import toolStyles from '../tools.module.css';
import cx from "classnames";
import {getPageTitle} from "../_helper";


class Login extends React.Component {
    static contextType = Context;

    state = {
        error: null
    };

    componentDidMount() {
        document.title = getPageTitle('Login');
    }

    submitHandler = (ev) => {
        this.setState({error: null});
        ev.preventDefault();
        this.context.fetch('api/auth/authenticate', "POST", JSON.stringify({
            username: this.usernameRef.value,
            password: this.passwordRef.value
        })).then(res => {
            if (res.status === 401) {
                throw Error("Invalid Credentials! Please Try Again.");
            } else if (res.status === 429) {
                return res.text().then((resText => {
                    throw Error(resText);
                }));
            } else {
                return res.json();
            }
        }).then(({_id, firstName, lastName, username, apiLocation, role}) => {
            this.context.login(_id, firstName, lastName, username, apiLocation, role);
        }).catch(err => {
            this.setState({error: err.message});
        });
    };

    render() {
        return (
            <div className={styles.flexContainer}>
                <div className={styles.outerWrapper}>
                    <div className={styles.innerWrapper}>
                        <h1 className={styles.loginTitle}>Login</h1>
                        <div>
                            <form onSubmit={this.submitHandler}>
                                <table className={styles.loginForm}>
                                    <tbody>
                                    {this.state.error && <tr>
                                        <td colSpan={2}><span className={toolStyles.alertText}>{this.state.error}</span>
                                        </td>
                                    </tr>}
                                    <tr>
                                        <td>
                                            <label className={styles.loginLabel}>Username</label>
                                        </td>
                                        <td>
                                            <input type={"text"} ref={(ref) => {
                                                this.usernameRef = ref;
                                            }} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={styles.loginLabel}>Password</label>
                                        </td>
                                        <td>
                                            <input ref={(ref) => {
                                                this.passwordRef = ref;
                                            }} type={"password"} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td/>
                                        <td>
                                            <button className={cx(toolStyles.button, styles.loginButton)}>Login</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
