import React, {Component} from 'react';
import Context from "../../context/context";
import styles from '../../tools.module.css';
import cx from "classnames";


class FileUploadTool extends Component {
    static contextType = Context;
    linkRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            uploadIsValid: true,
            uploadError: false,
            fileToUpload: null,
            fileTooLarge: false,
            emptyError: false,
            downloadError: false,
            downloadAvailable: false
        };
    }

    handleFileChange = (ev) => {
        ev.preventDefault();
        this.setState({
            fileToUpload: this.uploadInput.files[0],
            uploadIsValid: true,
            uploadError: false,
            fileTooLarge: false,
            downloadError: false
        });
    };

    handleUploadZip = (ev) => {
        ev.preventDefault();
        if (!this.state.fileToUpload || !this.state.fileToUpload.name || !this.state.fileToUpload.name.endsWith('.zip')) {
            this.setState({uploadIsValid: false, fileToUpload: null});
        } else if (this.state.fileToUpload.size >= (25 * 1024 * 1024)) {
            this.setState({fileTooLarge: true, fileToUpload: null});
        } else {
            const formData = new FormData();
            formData.append("zip", this.state.fileToUpload);
            this.context.fetch(`api/submission/upload`, "PUT", formData, true)
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error("Something Went Wrong")
                    }
                    return res.json();
                })
                .then(resData => {
                    this.uploadInput.value = '';
                    this.setState({fileToUpload: null, uploadError: !resData.uploaded, uploadIsValid: resData.valid});
                    if (this.props.refreshFileTree) {
                        this.props.refreshFileTree();
                    }
                })
                .catch(err => {
                    this.setState({fileToUpload: null, uploadError: true});
                });
        }
    };


    handleEmptyFolder = (ev) => {
        ev.preventDefault();
        const confirmation = window.confirm(`Are you sure you want to empty your folder? (This cannot be undone!)`);
        if (confirmation) {
            this.setState({
                emptyError: false,
            });
            this.context.fetch('api/submission/upload', "DELETE")
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error("Something Went Wrong")
                    }
                    return res.json();
                })
                .then(resData => {
                    this.setState({emptied: !resData.emptied});
                    if (this.props.refreshFileTree) {
                        this.props.refreshFileTree();
                    }
                })
                .catch(err => {
                    this.setState({emptyError: true});
                });
        }
    };

    handleDownloadFolder = (ev) => {
        ev.preventDefault();
        let link = `download.zip`;
        this.context.fetch('api/submission/upload')
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("Something Went Wrong")
                }
                return res.blob().then((blob) => {
                    const disposition = res.headers.get('content-disposition');
                    if (disposition) {
                        if (disposition.indexOf('attachment') !== -1) {
                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            const matches = filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) {
                                link = matches[1].replace(/['"]/g, '');
                            }
                        } else if (disposition.indexOf('inline') !== -1) {
                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            const matches = filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) {
                                link = matches[1].replace(/['"]/g, '');
                            }
                        }
                    }
                    const a = this.linkRef.current;
                    a.download = link;
                    a.href = window.URL.createObjectURL(blob);
                    this.setState({downloadAvailable: true, downloadError: false});
                });
            }).catch(err => {
            this.setState({downloadError: true});
        });
    };

    render() {
        return (
            <div className={styles.tool}>
                <div className={styles.toolInternal}>
                    <h2 className={styles.toolSubTitle}>Upload Your Zip File Here</h2>
                    <form onSubmit={this.handleUploadZip}>
                        <table>
                            <tbody>
                            {!this.state.uploadIsValid &&
                            <tr>
                                <td>
                                    <span className={styles.alertText}>You must select a zip file.</span>
                                </td>
                            </tr>
                            }

                            {this.state.fileTooLarge &&
                            <tr>
                                <td>
                                    <span className={styles.alertText}>Your file must not be larger than 25MB!</span>
                                </td>
                            </tr>
                            }
                            {this.state.uploadError &&
                            <tr>
                                <td>
                                    <span
                                        className={styles.alertText}>Something went wrong!</span>
                                </td>
                            </tr>}
                            <tr>
                                <td>
                                    {!(this.state.fileToUpload) &&
                                    <label htmlFor={'file-upload'}>
                                        <div className={cx(styles.button, styles.largeButtonText)}>
                                            <img alt="" className={styles.uploadIcon}
                                                 src={'./upload-icon.png'}/>
                                            Click Here to Select Your Zip File
                                        </div>
                                    </label>}
                                </td>
                            </tr>
                            <tr className={styles.hideThis}>
                                <td>
                                    <input id={'file-upload'} ref={(ref) => {
                                        this.uploadInput = ref
                                    }} onChange={this.handleFileChange} type="file"/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {(this.state.fileToUpload) &&
                                    <React.Fragment>
                                        <button style={{"display": "inline-block"}}
                                                className={cx(styles.pulseButton, styles.largeButtonText)}><img
                                            alt="" className={styles.uploadIcon}
                                            src={'./upload-icon.png'}/>Upload
                                        </button>
                                        <button style={{"display": "inline-block"}}
                                                className={cx(styles.button, styles.largeButtonText)}
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    this.uploadInput.value = '';
                                                    this.setState({fileToUpload: null})
                                                }}><img alt="" className={styles.uploadIcon}
                                                        src={'./cancel-icon.png'}/> Cancel
                                        </button>
                                    </React.Fragment>}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                    <h2 className={styles.toolSubTitle}>Empty Your Folder</h2>
                    <form onSubmit={this.handleEmptyFolder}>
                        <table>
                            <tbody>
                            {this.state.emptyError &&
                            <tr>
                                <td>
                                    <span
                                        className={styles.alertText}>Something went wrong!</span>
                                </td>
                            </tr>}
                            <tr>
                                <td>
                                    <button className={cx(styles.button, styles.largeButtonText)}>Empty My Folder
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>

                    <h2 className={styles.toolSubTitle}>Download Your Folder</h2>
                    <form onSubmit={this.handleDownloadFolder}>
                        <table>
                            <tbody>
                            {this.state.downloadError &&
                            <tr>
                                <td>
                                    <span
                                        className={styles.alertText}>Something went wrong!</span>
                                </td>
                            </tr>}
                            <tr>
                                <td>
                                    {!this.state.downloadAvailable &&
                                    <button className={cx(styles.button, styles.largeButtonText)}>Download My
                                        Folder</button>}
                                    <a ref={this.linkRef}
                                       className={this.state.downloadAvailable ? cx(styles.pulseButton, styles.largeButtonText) : styles.hideThis}
                                       onClick={() => this.setState({downloadAvailable: false})}>Download Zip</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        )
    }
}

export default FileUploadTool;