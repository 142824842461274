import React from 'react';
import styles from './studentTiles.module.css'

import Context from "../context/context";
import IFramePlaceHolder from "./iframePlaceHolder";
import IFrameLazyLoader from "./iframeLazyLoader";
import {getPageTitle} from "../_helper";
import {Menu, Item, Separator, animation, MenuProvider} from 'react-contexify';
import {NavLink} from "react-router-dom";

class StudentTiles extends React.Component {
    static contextType = Context;

    state = {
        studentTiles: []
    };

    componentDidMount() {
        document.title = getPageTitle();
        this.context.fetch('api/students/tiles')
            .then(res => res.json())
            .then(resData => {
                this.setState({studentTiles: resData});
            });
    }


    render() {
        return <React.Fragment>
            <div className={styles.wrapper}>
                {this.state.studentTiles.map((student, index) => {
                    const src_path = `/view/${student._id}`;
                    const live_path = student.apiLocation;
                    return <React.Fragment>
                        <div key={index} className={styles.tile}>
                            <MenuProvider id={student._id} className={styles.menuProvider}>
                                <div className={styles.overlay} onClick={(ev) => {
                                    ev.preventDefault();
                                    this.props.handleStudentTileSelect(student._id, student.folder, `${student.firstName} ${student.lastName}`, src_path, live_path)
                                }}/>
                            </MenuProvider>
                            {student.folder ?
                                <IFrameLazyLoader>
                                    <iframe title={student._id} className={styles.iframe} scrolling={"no"}
                                            src={student.apiLocation} sandbox={""}/>
                                </IFrameLazyLoader> :
                                <IFramePlaceHolder placeHolderText={"Nothing Here Yet"}/>}
                            <span>{`${student.firstName} ${student.lastName}`}</span>
                        </div>
                        <Menu id={student._id} animation={animation.zoom}>
                            <Item>
                                <NavLink className={"react-contexify__item__link unStyled"} to={src_path}>
                                    View Website Source
                                </NavLink>
                            </Item>
                            <Separator/>
                            <Item>
                                <a className={"react-contexify__item__link unStyled"} href={live_path}>
                                    View Full Website
                                </a>
                            </Item>
                        </Menu>
                    </React.Fragment>
                })}
            </div>
        </React.Fragment>;
    }
}

/*

                                                               <NavLink
                                                                   className={cx(toolStyles.button, toolStyles.largeButtonText, toolStyles.centeredText)}
                                                                   to={this.state.modal.src_path}
                                                                   onClick={this.handleHideModal}>
                                                                   View Website Source</NavLink>

                            */

export default StudentTiles;