import React from 'react';

/***
 * Defines Context
 * These functions are persistent across components giving the entire application the ability to access basic
 * information about the user.
 *
 * Functions definitions are in client/App.js
 */
export default React.createContext({
    firstName: null,
    lastName: null,
    apiLocation: null,
    _id: null,
    role: null,
    login: () => {
    },
    logout: () => {
    },
    fetch: () => {
    }
});