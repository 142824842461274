import React from 'react';
import LazyLoad from "react-lazyload";
import IFramePlaceHolder from "./iframePlaceHolder";

class IFrameLazyLoader extends React.Component {
    render() {
        return <LazyLoad offset={200} overflow={true}
                         placeholder={<IFramePlaceHolder placeHolderText={"Loading..."}/>}>
            {this.props.children}
        </LazyLoad>
    }
}

export default IFrameLazyLoader;