import React, {Component} from 'react';
import Context from "../../context/context";
import toolStyles from '../../tools.module.css';
import {NavLink} from "react-router-dom";
import StudentFileDownloadButton from "./handleStudentDownloadButton";
import StudentFileUploadButton from "./handleStudentUploadButton";
import cx from "classnames";
import StudentFileEmptyButton from "./handleStudentEmptyButton";


class StudentFileManager extends Component {
    static contextType = Context;

    refs = {};

    render() {
        return (
            <div className={toolStyles.bigTool}>
                <div className={toolStyles.toolInternal}>
                    <h1 className={toolStyles.toolTitle}>Student Submission Manager</h1>
                    <table className={toolStyles.table}>
                        <thead>
                        <tr className={toolStyles.tr}>
                            <th className={cx(toolStyles.cell, toolStyles.th)}>First Name</th>
                            <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Last Name</th>
                            <th className={cx(toolStyles.cell, toolStyles.th)}>Upload</th>
                            <th className={cx(toolStyles.cell, toolStyles.th)}>Link</th>
                            <th className={cx(toolStyles.cell, toolStyles.th)}>Download</th>
                            <th className={cx(toolStyles.cell, toolStyles.th)}>Delete</th>
                        </tr>
                        </thead>
                        {this.props.students.length > 0 && <tbody>{this.props.students.map((s, i) => {
                            return <tr key={i} className={toolStyles.tr}>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>{s.firstName}</td>
                                <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{s.lastName}</td>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <StudentFileUploadButton userId={s._id} firstName={s.firstName} lastName={s.lastName}  refreshStudents={this.props.refreshStudents}/>
                                </td>

                                {s.folder ? <React.Fragment>
                                        <td className={cx(toolStyles.cell, toolStyles.td)}>
                                            <NavLink className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                                     to={`/view/${s._id}`}>Submission</NavLink>
                                        </td>
                                        <td className={cx(toolStyles.cell, toolStyles.td)}>
                                            <StudentFileDownloadButton userId={s._id}/>
                                        </td>
                                        <td className={cx(toolStyles.cell, toolStyles.td)}>
                                            <StudentFileEmptyButton firstName={s.firstName} lastName={s.lastName}
                                                                    userId={s._id} refreshStudents={this.props.refreshStudents}/>
                                        </td>
                                    </React.Fragment> :
                                    <td className={cx(toolStyles.cell, toolStyles.td)} colSpan={3}>No Submission
                                        Available</td>
                                }
                            </tr>;
                        })}</tbody>}
                    </table>
                </div>
            </div>
        )
    }
}

export default StudentFileManager;