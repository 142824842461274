import React from 'react';

import Context from "../../context/context";
import PasswordResetComponent from "../../toolBoxtools/passwordResetComponent/passwordResetTool";
import styles from '../toolBoxStylings.module.css';
import StudentFileManager from "../../toolBoxtools/studentFileManager/studentFileManager";
import StudentUserManager from "../../toolBoxtools/studentUserManager/studentUserManager";
import ToolLinks from "../../toolBoxtools/linkList/linkList";
import SubmissionHistoryView from "../../toolBoxtools/userActivityViews/submissionHistory";
import {getPageTitle} from "../../_helper";

class FacilitatorToolBox extends React.Component {
    static contextType = Context;

    state = {students: []};

    refreshStudents = () => {
        this.context.fetch('api/users/students')
            .then(res => res.json())
            .then(resData => {
                this.setState({students: resData});
            });
    };

    componentDidMount() {
        document.title = getPageTitle(`${this.context.role.charAt(0).toUpperCase() + this.context.role.slice(1)} ToolBox`);
        this.refreshStudents();
    }


    render() {
        return <div className={styles.toolboxWrapper}>
            <StudentFileManager students={this.state.students} refreshStudents={this.refreshStudents}/>
            <StudentUserManager students={this.state.students} refreshStudents={this.refreshStudents}/>
            <SubmissionHistoryView/>
            <PasswordResetComponent/>
            <ToolLinks/>
        </div>;
    }
}

export default FacilitatorToolBox;