import React, {Component} from 'react';
import styles from './CatchAllException.module.css';
import {getPageTitle} from "../_helper";

class CatchAllException extends Component {

    componentDidMount() {
        document.title = getPageTitle('Page not Found');
    }

    render() {
        const thingsToCheck = ["Did you name your file correctly?",
            "Did you upload your files to the correct place?",
            "Did you upload the correct files?",
            "Are your files unzipped?"];
        return (
            <div className={styles.errorCatchAll}>
                <div className={styles.wrapper}>
                    <div className={styles.innerText}>
                        <h1>No &ldquo;index.html&rdquo; file found here!</h1>
                        <h2>Things to Check:</h2>
                        <ol>
                            {
                                thingsToCheck.map((t, i) => {
                                    return (
                                        <li key={i} className={styles.listItem}>{t}
                                        </li>
                                    )
                                })
                            }
                        </ol>
                    </div>
                </div>
            </div>);
    }
}

export default CatchAllException;
