import React, {Component} from 'react';
import Context from "../../context/context";
import toolStyles from '../../tools.module.css';
import cx from 'classnames';


class StudentFileDownloadButton extends Component {
    static contextType = Context;
    state = {downloadAvailable: false};

    ref = null;


    currentStudentDownload = () => {
        return this.context.fetch(`api/submission/upload/${this.props.userId}`);
    };

    archiveDownload = () => {
        return this.context.fetch(`api/submission/archive/upload/${this.props.folder}/${this.props.username}`);
    };

    handleDownload = (ev) => {
        ev.preventDefault();
        this.setState({downloadAvailable: false}, () => {
            let link = `download.zip`;
            (this.props.userId ? this.currentStudentDownload() : this.archiveDownload())
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error("Something Went Wrong")
                    }
                    return res.blob().then((blob) => {
                        const disposition = res.headers.get('content-disposition');
                        if (disposition) {
                            if (disposition.indexOf('attachment') !== -1) {
                                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                const matches = filenameRegex.exec(disposition);
                                if (matches != null && matches[1]) {
                                    link = matches[1].replace(/['"]/g, '');
                                }
                            } else if (disposition.indexOf('inline') !== -1) {
                                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                const matches = filenameRegex.exec(disposition);
                                if (matches != null && matches[1]) {
                                    link = matches[1].replace(/['"]/g, '');
                                }
                            }
                        }
                        const a = this.ref;
                        a.download = link;
                        a.href = window.URL.createObjectURL(blob);
                        this.setState({downloadAvailable: true});
                    });
                });
        });
    };

    render() {
        return (
            <React.Fragment>
                {!this.state.downloadAvailable &&
                <button onClick={this.handleDownload}
                        className={cx(toolStyles.button, toolStyles.smallButtonText)}>Download{this.props.username ? ` ${this.props.username}'s Submission` : ''}</button>}
                <a ref={ref => this.ref = ref}
                   className={!this.state.downloadAvailable ? toolStyles.hideThis : cx(toolStyles.button, toolStyles.smallButtonText)}
                   onClick={() => this.setState({downloadAvailable: false})}>Download Zip</a>
            </React.Fragment>
        )
    }
}

export default StudentFileDownloadButton;