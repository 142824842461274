import React, {Component} from 'react';
import Context from "../../context/context";
import toolStyles from '../../tools.module.css';
import cx from "classnames";
import {inputValueSafeNull} from "../../_helper";


class StudentUserManager extends Component {
    static contextType = Context;

    state = {error: ''};


    handlePasswordReset = (userId, pNumber, displayName) => (ev) => {
        ev.preventDefault();
        if (window.confirm(`Are you sure you want to reset ${displayName}'s password? This cannot be undone! It will be reset to the system default.`)) {
            this.context.fetch(`api/users/student/passwordReset/${userId}`).then(res => {
                if (res.status === 200) {
                    window.alert(`Password was reset to Pass${pNumber.slice(pNumber.length - 4)}`)
                }
            })
        }
    };

    handleDelete = (userId, displayName) => (ev) => {
        ev.preventDefault();
        if (window.confirm(`Are you sure you want to delete ${displayName}? This cannot be undone!`)) {
            this.context.fetch(`api/users/student/${userId}`, "DELETE").then(res => {
                if (res.status === 200) {
                    this.props.refreshStudents();
                }
            })
        }
    };


    handleInputChange = (ev) => {
        this.setState({
            [ev.target.id]: ev.target.value
        });
    };

    handleCreateStudent = (ev) => {
        ev.preventDefault();
        this.context.fetch(`api/users/student`, 'POST', JSON.stringify({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            pNumber: this.state.pNumber
        })).then(res => {
            if (res.status === 200) {
                this.props.refreshStudents();
                this.setState({firstName: '', lastName: '', pNumber: '', error: ''});
            }
            if (res.status === 400) {
                this.setState({error: 'Bad Request.'});
            }
            if (res.status === 409) {
                this.setState({error: 'Conflict. PNumber already in system.'})
            }
        })
    };


    render() {
        return (
            <div className={toolStyles.bigTool}>
                <div className={toolStyles.toolInternal}>
                    <h1 className={toolStyles.toolTitle}>Student Account Manager</h1>
                    <form onSubmit={this.handleCreateStudent}>
                        <table className={toolStyles.table}>
                            <thead>
                            <tr className={toolStyles.tr}>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>First Name</th>
                                <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Last Name
                                </th>
                                <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>PNumber/Username</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>Reset Password</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.students.length > 0 && this.props.students.map((s, i) => {
                                return <tr key={i} className={toolStyles.tr}>
                                    <td className={cx(toolStyles.cell, toolStyles.td)}>{s.firstName}</td>
                                    <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{s.lastName}</td>
                                    <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{s.username}</td>
                                    <td className={cx(toolStyles.cell, toolStyles.td)}>
                                        <button
                                            onClick={this.handlePasswordReset(s._id, s.username, `${s.firstName} ${s.lastName}`)}
                                            className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                            type={"button"}>
                                            Reset Password
                                        </button>
                                    </td>
                                    <td className={cx(toolStyles.cell, toolStyles.td)}>
                                        <button onClick={this.handleDelete(s._id, `${s.firstName} ${s.lastName}`)}
                                                className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                                type={"button"}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>;
                            })}

                            <tr className={toolStyles.tr}>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>New First Name</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>New Last Name</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>New P-Number</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)} colSpan={2}/>
                            </tr>
                            <tr className={toolStyles.tr}>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <input type={'text'} id={'firstName'}
                                           value={inputValueSafeNull(this.state.firstName)}
                                           onChange={this.handleInputChange} required/></td>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <input type={'text'} id={'lastName'}
                                           value={inputValueSafeNull(this.state.lastName)}
                                           onChange={this.handleInputChange} required/></td>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <input type={'text'} id={'pNumber'}
                                           value={inputValueSafeNull(this.state.pNumber)}
                                           onChange={this.handleInputChange} required/></td>
                                <td className={cx(toolStyles.cell, toolStyles.td)} colSpan={2}>
                                    <button style={{margin: "auto"}}
                                            className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                            type={"submit"}>
                                        Add Student
                                    </button>
                                </td>
                            </tr>
                            {this.state.error && <tr className={toolStyles.tr}>
                                <td className={cx(toolStyles.cell, toolStyles.td)} colSpan={5}>
                                    <span className={toolStyles.alertText}>{this.state.error}</span>
                                </td>
                            </tr>}
                            </tbody>
                        </table>

                    </form>
                </div>
            </div>
        )
    }
}

export default StudentUserManager;