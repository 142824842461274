import React, {Component} from 'react';
import toolStyles from "../../tools.module.css";
import cx from "classnames";
import Context from "../../context/context";


class LoginHistoryView extends Component {
    static contextType = Context;

    state = {loginEvents: []};

    componentDidMount() {
        this.context.fetch('api/users/loginHistory')
            .then(res => res.json())
            .then(res => this.setState({loginEvents: res}));
    }

    handleClearHistory = (ev) => {
        ev.preventDefault();
        if (this.context.role === "admin") {
            if (window.confirm("Are you sure you want to to clear login history? This cannot be undone.")) {
                this.context.fetch('api/users/loginHistory', "DELETE").then(res => {
                    if (res.status === 200 || res.status === 204) {
                        this.setState({loginEvents: []})
                    }
                });
            }
        }
    };

    render() {
        return (<div className={toolStyles.tool}>
                <div className={toolStyles.toolInternal}>
                    <h2 className={toolStyles.toolSubTitle}>Login History</h2>
                    <table className={cx(toolStyles.table, toolStyles.abridged)}>
                        <thead>
                        <tr className={toolStyles.tr}>
                            <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>First Name</th>
                            <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Last Name</th>
                            <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Role</th>
                            <th className={cx(toolStyles.cell, toolStyles.th)}>Login</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.loginEvents.length > 0 && this.state.loginEvents.map(({_id, firstName, lastName, role, time}) => {
                            const d = new Date(time);
                            return <tr key={_id} className={toolStyles.tr}>
                                <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{firstName}</td>
                                <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{lastName}</td>
                                <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{role}</td>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>{`${d.toLocaleString()}`}</td>
                            </tr>
                        })}
                        {(this.state.loginEvents.length > 0 && this.context.role === "admin") &&
                        <tr className={toolStyles.tr}>
                            <td className={cx(toolStyles.cell, toolStyles.td)} colSpan={4}>
                                <button onClick={this.handleClearHistory}
                                        className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                        type={"button"}>
                                    Clear Login History
                                </button>
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default LoginHistoryView;