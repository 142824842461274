const inputValueSafeNull = (v) => {
    return !(v === null || v === undefined) ? v.trim() : '';
};


const getPageTitle = (...s) => {
    return (s.length > 0) ? `IST-226 - ${s.join(' - ')}` : "IST-226 - CPTWEBDEVL";
};


const padZero = (str, len) => {
    len = len || 2;
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
};

const generateRandomColor = () => {
    return `#${padZero(Math.floor(Math.random() * 16777215).toString(16), 6)}`;
};

const invertColor = (hex) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
};

const generateContrastingBgFgColor = () => {
    const hex = generateRandomColor();
    return {backgroundColor: hex, color: invertColor(hex)}
};

module.exports = {inputValueSafeNull, generateContrastingBgFgColor, getPageTitle};