import React, {Component} from 'react';
import Context from "../../context/context";
import styles from '../../tools.module.css';
import cx from "classnames";
import {inputValueSafeNull} from "../../_helper";


class PasswordResetComponent extends Component {
    static contextType = Context;

    state = {
        notification: null, fieldType: "password",
    };

    handleInputChange = (ev) => {
        this.setState({
            notification: null,
            [ev.target.id]: ev.target.value
        });
    };


    validatePasswords = () => {
        if (this.state.newPassword !== this.state.newPassword2) {
            this.setState({
                notification: "Passwords Must Match"
            });
            return false;
        }
        if (this.state.currentPassword === this.state.newPassword) {
            this.setState({
                notification: "New password cannot be the same as the old password"
            });
            return false;

        }
        return true;
    };

    handlePasswordChange = (ev) => {
        ev.preventDefault();
        if (this.validatePasswords()) {
            this.context.fetch(`api/users/password/change`, 'POST', JSON.stringify({
                currentPassword: this.state.currentPassword,
                newPassword: this.state.newPassword
            }))
                .then(res => {
                        if (res.status < 300) {
                            this.setState({
                                notification: "Updated!",
                                currentPassword: "",
                                newPassword: "",
                                newPassword2: ""
                            });
                        } else if (res.status === 500) {
                            this.setState({notification: "Current Password is incorrect!"})
                        } else {
                            this.setState({
                                notification: "Something Went Wrong. Refresh and Try Again!"
                            })
                        }
                    }
                );
        }
    };

    handlePasswordFieldDisplay = (ev) => {
        ev.preventDefault();
        if (this.state.fieldType === 'password') {
            this.setState({fieldType: "text"});
        } else {
            this.setState({fieldType: "password"});
        }
    };

    render() {
        return <div className={styles.tool}>
            <div className={styles.toolInternal}>
                <h2 className={styles.toolSubTitle}>Password Management</h2>
                <form onSubmit={this.handlePasswordChange} style={{"width": 350}}>
                    <table>
                        <tbody>
                        <tr>
                            <td><label htmlFor={'currentPassword'}>Current Password:</label></td>
                            <td><input type={this.state.fieldType} id={'currentPassword'}
                                       value={inputValueSafeNull(this.state.currentPassword)}
                                       onChange={this.handleInputChange} required/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={'newPassword'}>New Password:</label></td>
                            <td><input type={this.state.fieldType} id={'newPassword'}
                                       value={inputValueSafeNull(this.state.newPassword)}
                                       onChange={this.handleInputChange} required/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={'newPassword2'}>Confirm New Password:</label></td>
                            <td><input type={this.state.fieldType} id={'newPassword2'}
                                       value={inputValueSafeNull(this.state.newPassword2)}
                                       onChange={this.handleInputChange} required/></td>
                        </tr>

                        {this.state.notification && <tr>
                            <td colSpan={2}><span className={styles.alertText}>{this.state.notification}</span></td>
                        </tr>}
                        <tr>
                            <td>
                                <button className={cx(styles.button, styles.smallButtonText)}>Submit</button>
                            </td>
                            <td>
                                <button className={cx(styles.button, styles.smallButtonText)}
                                        onClick={this.handlePasswordFieldDisplay}>{this.state.fieldType === 'password' ? "Show" : "Hide"} Passwords
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    }
}


export default PasswordResetComponent;
