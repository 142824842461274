import React, {Component} from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import {html_beautify, css_beautify, js_beautify} from 'js-beautify';
import Context from "../context/context";
import styles from './submissionPlainTextView.module.css';

const styleObj = (s, b, t) => {
    return {
        style: s,
        beautify: b,
        type: t
    }
};

const validExts = {
    ".html": styleObj(Prism.languages.html, html_beautify, "html"),
    ".htm": styleObj(Prism.languages.html, html_beautify, "html"),
    ".txt": true,
    ".css": styleObj(Prism.languages.css, css_beautify, "css"),
    ".js": styleObj(Prism.languages.javascript, js_beautify, "js"),
    '.md': true
};


const noSrcMessage = <div>
    <h1>No Valid File Selected!</h1>
    <p>Please select a valid file in order to proceed.</p>
    <h2>List of Valid File Extensions</h2>
    <ul>
        {Object.keys(validExts).map((e, i) => (<li key={i.toString()}>{`*${e}`}</li>))}
    </ul>
</div>;

const hasValidExtension = (fileName) => {
    if (new RegExp('(' + Object.keys(validExts).join('|').replace(/\./g, '\\.') + ')$').test(fileName)) {
        return validExts[`.${fileName.split('.').slice(-1)[0]}`];
    } else {
        return false;
    }
};


class SubmissionPlainTextView extends Component {
    static contextType = Context;

    state = {src: null, data: '', format: false};

    getFileData = () => {
        if (this.props.src) {
            if (this.props.src !== this.state.src) {
                const format = hasValidExtension(this.props.src);
                if (format) {
                    this.context.fetch(`api/students/submission/${this.props.studentId}/file/${this.props.src}`)
                        .then(res => res.json())
                        .then(resData => {
                            this.setState({
                                    src: this.props.src,
                                    data: resData.fileContents,
                                    format: format
                                }
                            );
                        })
                } else {
                    this.setState({src: this.props.src, data: '', format: false});
                }
            }
        }
    };

    componentDidMount() {
        this.getFileData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.getFileData();
    }

    render() {
        let view = noSrcMessage;
        if (this.props.src && this.state.format instanceof Object) {
            view = <code
                dangerouslySetInnerHTML={{__html: Prism.highlight(this.state.format.beautify(this.state.data), this.state.format.style, this.state.format.type)}}/>
        }

        return (
            <pre className={`line-numbers ${styles.src}`}>
                    {view}
            </pre>
        );
    }
}

export default SubmissionPlainTextView;
