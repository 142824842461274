import React from 'react';
import styles from './studentTiles.module.css';

import Context from "../context/context";
import IFrameLazyLoader from "./iframeLazyLoader";
import {getPageTitle} from "../_helper";
import LazyLoad from "react-lazyload";
import StudentFileDownloadButton from "../toolBoxtools/studentFileManager/handleStudentDownloadButton";

class ArchiveTiles extends React.Component {
    static contextType = Context;

    state = {
        archiveTiles: []
    };

    componentDidMount() {
        document.title = getPageTitle("Archive");
        this.context.fetch('api/archives/tiles')
            .then(res => res.json())
            .then(resData => {
                this.setState({archiveTiles: resData});
            });
    }

    render() {
        return <React.Fragment>
            {this.state.archiveTiles.map(([d, tiles]) => {
                return <LazyLoad offset={200} overflow={true}>
                    <h1 className={styles.archiveTitle}>{d}</h1>
                    <div className={styles.wrapper}>
                        {tiles.map((student, index) => {
                            const splits = student.location.split('/');
                            const folder = splits[2];
                            const username = splits[3];
                            return <div key={index}>
                                <div className={styles.tile}>
                                    <a className={styles.overlay} href={student.location}/>
                                    <IFrameLazyLoader>
                                        <iframe title={student.displayName} className={styles.iframe} scrolling={"no"}
                                                src={student.location} sandbox={""}/>
                                    </IFrameLazyLoader>
                                    <span>{student.displayName}</span>
                                </div>
                                <div className={styles.archiveDownloadButtonContainer}>
                                    {
                                        ["instructor", "admin"].includes(this.context.role) &&
                                        <StudentFileDownloadButton folder={folder} username={username}/>
                                    }
                                </div>
                            </div>
                        })}
                    </div>
                </LazyLoad>
            })}
        </React.Fragment>
    }
}

export default ArchiveTiles;