import React from 'react';
import PropTypes from 'prop-types';
import styles from './filetree.module.css'

class File extends React.Component {
    triggerAction = () => {
        if (this.props.action) {
            const file = {
                id: this.props.id,
                name: this.props.name,
                path: this.props.path,
                type: 'file'
            };

            this.props.action(file);
        }
    };

    render() {
        return (
            <div className={styles.file} onClick={this.triggerAction}>
                <img className={styles.icon} alt="" src={this.props.icon}/>
                <span className={styles.nested_label}>{this.props.name}</span>
            </div>
        );
    }
}

File.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    currentSelection: PropTypes.string,
    action: PropTypes.func,
};

export {
    File,
};