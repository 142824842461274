import React, {Component} from 'react';
import Context from "../../context/context";
import toolStyles from '../../tools.module.css';
import cx from 'classnames';


class StudentFileUploadButton extends Component {
    static contextType = Context;
    state = {
        uploadIsValid: true,
        uploadError: false,
        fileToUpload: null,
        fileTooLarge: false,
    };

    uploadInput = {files: []};


    handleFileChange = (ev) => {
        ev.preventDefault();
        this.setState({
            fileToUpload: this.uploadInput.files[0],
            uploadIsValid: true,
            uploadError: false,
            fileTooLarge: false,
            downloadError: false
        });
    };

    handleUploadZip = (ev) => {
        ev.preventDefault();
        if (!this.state.fileToUpload || !this.state.fileToUpload.name || !this.state.fileToUpload.name.endsWith('.zip')) {
            this.setState({uploadIsValid: false, fileToUpload: null});
        } else if (this.state.fileToUpload.size >= (25 * 1024 * 1024)) {
            this.setState({fileTooLarge: true, fileToUpload: null});
        } else {
            if (window.confirm(`Are you sure you want to upload on ${this.props.firstName} ${this.props.lastName}'s behalf? It will permanently overwrite their current submission.`)) {
                const formData = new FormData();
                formData.append("zip", this.state.fileToUpload);
                this.context.fetch(`api/submission/upload/${this.props.userId}`, "PUT", formData, true)
                    .then(res => {
                        if (res.status !== 200 && res.status !== 201) {
                            throw new Error("Something Went Wrong")
                        }
                        return res.json();
                    }).then(resData => {
                    this.uploadInput.value = '';
                    this.setState({fileToUpload: null, uploadError: !resData.uploaded, uploadIsValid: resData.valid});
                }).catch(err => {
                    this.setState({fileToUpload: null, uploadError: true});
                }).finally(() => {
                    if (this.props.refreshStudents) {
                        this.props.refreshStudents();
                    }
                })
            } else {
                this.handleCancelUpload(ev);
            }
        }
    };

    handleCancelUpload = (ev) => {
        ev.preventDefault();
        this.uploadInput.value = '';
        this.setState({fileToUpload: null})
    };

    render() {
        return (<React.Fragment>
                {!this.state.uploadIsValid && <span className={toolStyles.alertText}>You must select a zip file.</span>}
                {this.state.fileTooLarge &&
                <span className={toolStyles.alertText}>Your file must not be larger than 25MB!</span>}
                {this.state.uploadError && <span className={toolStyles.alertText}>Something went wrong!</span>}
                {!(this.state.fileToUpload) &&
                <label htmlFor={`file-upload-${this.props.userId}`}>
                    <div className={cx(toolStyles.button, toolStyles.smallButtonText)}>
                        <img alt="" className={toolStyles.smallUploadIcon} src={'./upload-icon.png'}/>
                        Upload Zip
                    </div>
                </label>}
                <div className={toolStyles.hideThis}>
                    <input id={`file-upload-${this.props.userId}`} ref={(ref) => {
                        this.uploadInput = ref
                    }} onChange={this.handleFileChange} type="file"/>
                </div>
                {(this.state.fileToUpload) &&
                <div style={{gridTemplateColumns: "1fr 1fr"}}>
                    <button className={cx(toolStyles.pulseButton, toolStyles.smallButtonText)}
                            onClick={this.handleUploadZip}>
                        <img alt="" className={toolStyles.smallUploadIcon} src={'./upload-icon.png'}/>Upload
                    </button>
                    <button className={cx(toolStyles.button, toolStyles.smallButtonText)}
                            onClick={this.handleCancelUpload}><img alt="" className={toolStyles.smallUploadIcon}
                                                                   src={'./cancel-icon.png'}/> Cancel
                    </button>
                </div>}
            </React.Fragment>
        )
    }
}

export default StudentFileUploadButton;