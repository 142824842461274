import React, {Component} from 'react';
import toolStyles from "../../tools.module.css";
import {NavLink} from "react-router-dom";
import cx from "classnames";


class ToolLinks extends Component {

    render() {
        return (<div className={toolStyles.tool}>
                <div className={toolStyles.toolInternal}>
                    <h2 className={toolStyles.toolSubTitle}>Links</h2>
                    <NavLink className={cx(toolStyles.button, toolStyles.largeButtonText)}
                             to={`/archive`}>View Archive</NavLink>
                </div>
            </div>
        )
    }
}

export default ToolLinks;