import React, {Component} from 'react';
import Context from "../../context/context";
import toolStyles from '../../tools.module.css';
import cx from 'classnames';


class StudentFileEmptyButton extends Component {
    static contextType = Context;

    state = {emptyError: false};

    handleEmptyFolder = (ev) => {
        ev.preventDefault();
        const confirmation = window.confirm(`Are you sure you want to ${this.props.firstName} ${this.props.lastName}'s your folder? (This cannot be undone!)`);
        if (confirmation) {
            this.setState({
                emptyError: false,
            });
            this.context.fetch(`api/submission/upload/${this.props.userId}`, "DELETE")
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error("Something Went Wrong")
                    }
                    return res.json();
                })
                .then(resData => {
                    this.setState({emptyError: !resData.emptied});
                })
                .catch(err => {
                    this.setState({emptyError: true});
                })
                .finally(() => {
                    if (this.props.refreshStudents) {
                        this.props.refreshStudents();
                    }
                })
        }
    };

    render() {
        return (<React.Fragment>
                {this.state.emptyError && <span className={toolStyles.alertText}>Something went wrong!</span>}
                <button className={cx(toolStyles.button, toolStyles.smallButtonText)}
                        onClick={this.handleEmptyFolder}>Empty
                </button>
            </React.Fragment>
        )
    }
}

export default StudentFileEmptyButton;