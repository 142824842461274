import React from 'react';

import Context from "../../context/context";
import FileTree from "../../FileTree/FileTree";
import PasswordResetComponent from "../../toolBoxtools/passwordResetComponent/passwordResetTool";
import FileUploadTool from "../../toolBoxtools/fileUpload/fileUploadTool";
import styles from '../toolBoxStylings.module.css';
import toolStyles from '../../tools.module.css';
import {NavLink} from "react-router-dom";
import cx from "classnames";
import {getPageTitle} from "../../_helper";

class StudentToolBox extends React.Component {
    static contextType = Context;

    state = {files: [], src: ''};

    refreshFileTree = () => {
        this.context.fetch(`api/students/submission/${this.context._id}`)
            .then(res => res.json())
            .then(resData => {
                this.setState({
                    files: resData.files,
                    src: resData.src
                });
            });
    };

    componentDidMount() {
        document.title = getPageTitle(`${this.context.role.charAt(0).toUpperCase() + this.context.role.slice(1)} ToolBox`);
        this.refreshFileTree();
    }


    render() {
        return <div className={styles.toolboxWrapper}>
            <div className={toolStyles.tool}>
                <div className={toolStyles.toolInternal} style={{alignItems: 'normal'}}>
                    <h2 className={toolStyles.toolSubTitle}>Current Submission Files</h2>
                    <div className={styles.fileTreeWrapper}>
                        <FileTree data={this.state.files} openMode={'closed'}/>
                    </div>
                    <h2 className={toolStyles.toolSubTitle}>Submission View Links</h2>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <a href={this.context.apiLocation}
                           className={cx(toolStyles.button, toolStyles.largeButtonText)}>
                            Live
                        </a>
                        <NavLink className={cx(toolStyles.button, toolStyles.largeButtonText)}
                                 to={`/view/${this.context._id}`}>Source View</NavLink>
                    </div>
                </div>
            </div>
            <FileUploadTool refreshFileTree={this.refreshFileTree}/>
            <PasswordResetComponent/>
        </div>;
    }
}

export default StudentToolBox;