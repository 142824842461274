import React from 'react';
import PropTypes from 'prop-types';
import styles from './filetree.module.css'

import {File} from './File.js';
import {Folder} from './Folder.js';

class TreeNode extends React.Component {
    constructor(props) {
        super(props);

        let defaultOpenState = false;
        if (this.props.openMode === 'open') {
            defaultOpenState = true;
        } else if (this.props.openMode === 'closed') {
            defaultOpenState = false;
        } else if (this.props.openMode === 'openFullDirs') {
            defaultOpenState = (this.props.children) ? this.props.children.length > 0 : false;
        }

        this.state = {
            open: defaultOpenState,
        };

        this.toggleFolder = this.toggleFolder.bind(this);
        this.toggleFolderHelper = this.toggleFolderHelper.bind(this);
    }

    toggleFolder = () => {
        if (this.props.conditionalToggle) {
            if (this.props.conditionalToggle(this.props, this.state)) {
                this.toggleFolderHelper();
            }
        } else {
            this.toggleFolderHelper();
        }
    };


    toggleFolderHelper() {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        if (this.props.children) {

            return (
                <div className={styles.folder}>
                    <Folder
                        name={this.props.name}
                        path={this.props.path}
                        open={this.state.open}
                        currentSelection={this.props.currentSelection}
                        conditionalToggle={this.props.conditionalToggle}
                        action={this.props.action}
                        toggleFolder={this.toggleFolder}
                    />
                    {
                        this.state.open &&
                        <div className={styles.nested_tree}>
                            {
                                this.props.children.map((child) => (
                                    <TreeNode
                                        key={child.ino}
                                        id={child.ino}
                                        openMode={this.props.openMode}
                                        name={child.name}
                                        path={child.path}
                                        determineIcon={this.props.determineIcon}
                                        icon={this.props.determineIcon(child.path)}
                                        currentSelection={this.props.currentSelection}
                                        conditionalToggle={this.props.conditionalToggle}
                                        children={child.children}
                                        action={this.props.action}
                                    />
                                ))
                            }
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <File
                    id={this.props.id}
                    name={this.props.name}
                    path={this.props.path}
                    icon={this.props.icon}
                    currentSelection={this.props.currentSelection}
                    action={this.props.action}
                />
            );
        }
    }
}

TreeNode.propTypes = {
    id: PropTypes.string.isRequired,
    openMode: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    determineIcon: PropTypes.func.isRequired,
    currentSelection: PropTypes.string,
    conditionalToggle: PropTypes.func,
    children: PropTypes.array,
    action: PropTypes.func,
};

export {
    TreeNode,
};