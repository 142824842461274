import React, {Component} from 'react';
import Context from "../../context/context";
import toolStyles from '../../tools.module.css';
import cx from "classnames";
import {inputValueSafeNull} from "../../_helper";


class BulkStudentManager extends Component {
    static contextType = Context;

    state = {blockingSubmit: false, blockingDelete: false};


    handleInputChange = (ev) => {
        this.setState({
            [ev.target.id]: ev.target.value
        });
    };


    handleDeleteAll = (ev) => {
        ev.preventDefault();
        if (!this.state.blockingDelete) {
            this.setState({blockingDelete: true}, () => {
                if (window.confirm(`Are you sure you want to archive/delete all students? This cannot be undone! This should not be done except at the end of the term.`)) {
                    this.context.fetch('api/users/students/all', "DELETE")
                        .then(res => {
                            if (res.status === 200 || res.status === 204) {
                                this.props.refreshStudents();
                            }
                        })
                        .finally(() => {
                            this.setState({blockingDelete: false});
                        });
                } else {
                    this.setState({blockingDelete: false});
                }
            })
        }

    };

    render() {
        return (
            <div className={toolStyles.bigTool}>
                <div className={toolStyles.toolInternal}>
                    <h1 className={toolStyles.toolTitle}>Bulk Student Manager</h1>
                    <div>
                        <h2 className={cx(toolStyles.toolSubTitle, toolStyles.taCenter)}>Delete All Students</h2>
                        <button style={{margin: "auto"}} onClick={this.handleDeleteAll}
                                className={cx(toolStyles.button, toolStyles.smallButtonText)}>Remove All Students
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default BulkStudentManager;