import React, {Component} from 'react';
import Context from "../../context/context";
import toolStyles from '../../tools.module.css';
import cx from "classnames";
import {inputValueSafeNull} from "../../_helper";


class UserManager extends Component {
    static contextType = Context;

    state = {
        error: '',
        users: [],
        userFirstName: '',
        userLastName: '',
        userUsername: '',
        userPassword: '',
        userRole: 'adjunct'
    };


    refreshUsers = () => {
        this.context.fetch('api/users/')
            .then(res => res.json())
            .then(resData => this.setState({users: resData}))
    };

    componentDidMount() {
        this.refreshUsers();
    }

    handleDelete = (userId, displayName) => (ev) => {
        ev.preventDefault();
        if (window.confirm(`Are you sure you want to delete ${displayName}? This cannot be undone!`)) {
            this.context.fetch(`api/users/del/${userId}`, "DELETE").then(res => {
                if (res.status === 200) {
                    this.refreshUsers();
                }
            })
        }
    };


    handlePasswordReset = (userId, displayName) => (ev) => {
        ev.preventDefault();
        if (window.confirm(`Are you sure you want to reset ${displayName}'s password? This cannot be undone!`)) {
            this.context.fetch(`api/users/reset/${userId}`).then(res => res.json()).then(resData => {
                window.alert(`Password set to:\n\n${resData.password}\n\nThis is also in the console log.`);
                console.log(resData.password);
            })
        }
    };


    handleInputChange = (ev) => {
        this.setState({
                [ev.target.id]: ev.target.value
            }
        );
    };

    handleCreateUser = (ev) => {
        ev.preventDefault();
        this.context.fetch(`api/users/new/${this.state.userRole}`, 'POST', JSON.stringify({
            firstName: this.state.userFirstName,
            lastName: this.state.userLastName,
            username: this.state.userUsername,
            password: this.state.userPassword,
        })).then(res => {
            if (res.status === 200) {
                this.refreshUsers();
                this.setState({
                    userFirstName: '',
                    userLastName: '',
                    userUsername: '',
                    userPassword: '',
                    userRole: 'adjunct'
                });
            }
            if (res.status === 400) {
                this.setState({error: 'Bad Request.'});
            }
            if (res.status === 409) {
                this.setState({error: 'Conflict. Username already in system.'})
            }
        })
    };


    render() {
        return (
            <div className={toolStyles.bigTool}>
                <div className={toolStyles.toolInternal}>
                    <h1 className={toolStyles.toolTitle}>Facilitator Account Manager</h1>
                    <form onSubmit={this.handleCreateUser}>
                        <table className={toolStyles.table}>
                            <thead>
                            <tr className={toolStyles.tr}>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>First Name</th>
                                <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Last Name
                                </th>
                                <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Username</th>
                                <th className={cx(toolStyles.cell, toolStyles.th, toolStyles.borderRight)}>Role</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>Reset Password</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.users.length > 0 && this.state.users.map(({_id, firstName, lastName, username, role}) => {
                                return <tr key={_id} className={toolStyles.tr}>
                                    <td className={cx(toolStyles.cell, toolStyles.td)}>{firstName}</td>
                                    <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{lastName}</td>
                                    <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{username}</td>
                                    <td className={cx(toolStyles.cell, toolStyles.td, toolStyles.borderRight)}>{role}</td>
                                    <td className={cx(toolStyles.cell, toolStyles.td)}>
                                        <button
                                            onClick={this.handlePasswordReset(_id, `${firstName} ${lastName} (${role})`)}
                                            className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                            type={"button"}>
                                            Reset Password
                                        </button>
                                    </td>
                                    <td className={cx(toolStyles.cell, toolStyles.td)}>
                                        <button onClick={this.handleDelete(_id, `${firstName} ${lastName} (${role})`)}
                                                className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                                type={"button"}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>;
                            })}

                            <tr className={toolStyles.tr}>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>New First Name</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>New Last Name</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>New Username</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>Role</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}>New Password</th>
                                <th className={cx(toolStyles.cell, toolStyles.th)}/>
                            </tr>
                            <tr className={toolStyles.tr}>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <input type={'text'} id={'userFirstName'}
                                           value={inputValueSafeNull(this.state.userFirstName)}
                                           onChange={this.handleInputChange} required/></td>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <input type={'text'} id={'userLastName'}
                                           value={inputValueSafeNull(this.state.userLastName)}
                                           onChange={this.handleInputChange} required/></td>
                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <input type={'text'} id={'userUsername'}
                                           value={inputValueSafeNull(this.state.userUsername)}
                                           onChange={this.handleInputChange} required/></td>

                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <select id={'userRole'}
                                            value={inputValueSafeNull(this.state.userRole)}
                                            onChange={this.handleInputChange} required>
                                        <option value={"adjunct"}>Adjunct</option>
                                        <option value={"instructor"}>Instructor</option>
                                        <option value={"admin"}>Admin</option>
                                    </select>
                                </td>

                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <input type={'text'} id={'userPassword'}
                                           value={inputValueSafeNull(this.state.userPassword)}
                                           onChange={this.handleInputChange} required/></td>


                                <td className={cx(toolStyles.cell, toolStyles.td)}>
                                    <button style={{margin: "auto"}}
                                            className={cx(toolStyles.button, toolStyles.smallButtonText)}
                                            type={"submit"}>
                                        Add User
                                    </button>
                                </td>
                            </tr>
                            {this.state.error && <tr className={toolStyles.tr}>
                                <td className={cx(toolStyles.cell, toolStyles.td)} colSpan={6}>
                                    <span className={toolStyles.alertText}>{this.state.error}</span>
                                </td>
                            </tr>}
                            </tbody>
                        </table>

                    </form>
                </div>
            </div>
        )
    }
}

export default UserManager;